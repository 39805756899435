import {Injectable} from '@angular/core';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '@env/environment';
import {StateService} from '@state/state-service/state.service';
import {defaultState} from '@state/model/default-state';
import {UserReduxAction} from '@state/actions/actions-user';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private oauthService: OAuthService, private stateService: StateService, private router: Router) {}

  async auth() {
    this.oauthService.configure(environment.oidc);

    if (this.oauthService.hasValidIdToken()) {
      const result = await this.oauthService.loadDiscoveryDocument();

      if (result) {
        this.getCurrentUserRecord();
      }
    } else {
      await this.oauthService.loadDiscoveryDocumentAndLogin({state: window.location.href});
      this.getCurrentUserRecord();
      try {
        let redirectUrl = new URL(decodeURIComponent(this.oauthService.state));
        const redirectPath = redirectUrl.pathname;
        const queryParams = {};
        redirectUrl.searchParams.forEach((value, key) => {
          queryParams[key] = value;
        });

        this.router.navigate([redirectPath], {replaceUrl: true, queryParams});
      } catch (e) {
        // if redirect does not work, do nothing
      }
    }

    this.oauthService.setupAutomaticSilentRefresh(undefined, 'id_token');
    this.setupAutomaticLogoutInCaseOfTokenExpiry();
  }

  async logout() {
    this.oauthService.logOut();
  }

  private getCurrentUserRecord() {
    const getUser: UserReduxAction = new UserReduxAction('USER_GET_CURRENT_LOAD', {});
    this.stateService.dispatch(getUser);
  }

  private setupAutomaticLogoutInCaseOfTokenExpiry() {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        window.location.href = `${document.location.protocol}//${document.location.host}`;
      }
    });
  }
}
