// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {AuthConfig} from 'angular-oauth2-oidc';
import {oidcConfig} from './oidc-config';
import {AppVersions} from '@state/state-service/do-mapper/model/communication/app-versions';

export interface Environment {
  name: 'local' | 'dev' | 'uat' | 'prod';
  production: boolean;
  oidc: AuthConfig;
  versions: AppVersions;
  url: {
    aws: string; // Amazon lambda backend
    flowTwin: string;
  };
  initializeSentry: boolean;
}

export const defaultEnvironment: Environment = {
  name: 'local',
  production: false,
  oidc: oidcConfig,
  versions: {
    frontend: {
      base: require('../../package.json').version
    },
    aws: {
      base: '0.0.0',
      unit: '0.0.0',
      communication: '0.0.0'
    },
    octave: {
      base: '0.0.0',
      common: '0.0.0',
      unit: '0.0.0',
      matlab: '0.0.0',
      communication: '0.0.0'
    }
  } as AppVersions,
  url: {
    aws: 'http://localhost:3000/local/app',
    flowTwin: 'http://localhost:4200/'
  },
  initializeSentry: false
};
