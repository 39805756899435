import {AuthConfig} from 'angular-oauth2-oidc';

export const oidcConfig: AuthConfig = {
  redirectUri: `${document.location.protocol}//${document.location.host}`,
  responseType: 'code',
  scope: 'openid profile email',

  silentRefreshTimeout: 20000,
  postLogoutRedirectUri: document.location.origin,
  showDebugInformation: false,
  strictDiscoveryDocumentValidation: false,
  clearHashAfterLogin: true,
  issuer: 'https://myendresstest.b2clogin.com/4d0b86e8-da9f-418b-9431-faae5dae88ed/B2C_1A_SignIn/v2.0/',
  clientId: '0b2b9bc5-a3b4-4310-a73c-41fc54c25962',
  skipIssuerCheck: true,
  requestAccessToken: true
};
