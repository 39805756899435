// Generic Appwide Modules
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {AppRoutingModule} from './app-routing.module';
import * as Sentry from '@sentry/angular-ivy';
import {Router} from '@angular/router';

// User Defined Components
import {AppComponent} from './app.component';

// User Defined Modules
import {StateModule} from '@state/state.module';

import {CoreModule} from '@core/core.module';
import {MaterialModule} from '@core/material/material.module';
import {MockModule} from '@core/mocks/mock.module';
import {AuthentificationModule} from '@core/authentification/authentification.module';

import {UIComponentsModule} from '@ui/ui-components/ui-components.module';
import {DialogModule} from '@ui/dialog/dialog.module';
import {SickFormsModule} from '@ui/sick-forms/sick-forms.module';
import {SickLayoutModule} from '@ui/sick-layout/sick-layout.module';

import {TranslateQuantityModule} from '@logic/translate-quantity/translate-quantity.module';

import {MatPaginatorIntl} from '@angular/material/paginator';

import {MatPaginatorI18nService} from './services/paginator/paginator.service';
import {FormlyWithTranslationProvider} from '@ui/sick-forms/translate-extension';
import {RoleGuardService} from '@core/guards/user-role-guard.service';
import {FormService} from '@ui/form/form.service';
import {LoggerService} from './services/logger/logger.service';
import {UnitSystemLabelPipe} from '@shared/pipes/unit-system-label/unit-system-label.pipe';
import {UnitConvertPipe} from '@shared/pipes/unit-convert/unit-convert.pipe';
import {AppModeService} from '@core/service-mode/app-mode.service';
import {CalcModeService} from './services/calc-mode/calc-mode.service';
import {LoggedInGuardService} from '@core/guards/logged-in-guard.service';
import {LoginErrorGuardService} from '@core/guards/login-error-guard.service';
import {AuthService} from '@core/authentification/auth.service';
import {StateService} from '@state/state-service/state.service';
import {environment} from '@env/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const envBasedModules = [];
if (environment.name === 'local') {
  envBasedModules.push(MockModule);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    AuthentificationModule,
    StateModule,
    TranslateQuantityModule,
    UIComponentsModule,
    DialogModule,
    SickFormsModule,
    SickLayoutModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormlyMaterialModule,
    FormlyModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => async () => await authService.auth(),
      deps: [AuthService, OAuthService, StateService],
      multi: true
    },
    FormlyWithTranslationProvider,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService
    },
    RoleGuardService,
    LoggedInGuardService,
    LoginErrorGuardService,
    FormService,
    LoggerService,
    UnitSystemLabelPipe,
    UnitConvertPipe,
    AppModeService,
    {
      provide: APP_INITIALIZER,
      useFactory: (calcModeService: CalcModeService) => () => calcModeService.initService(),
      deps: [CalcModeService],
      multi: true
    },
    TranslateService,
    {
      provide: APP_INITIALIZER,
      // Making sure, that the translate service is ready before the router triggers the setting of the page title
      useFactory: (translateService: TranslateService) => () => translateService.get(['']).toPromise(),
      deps: [TranslateService],
      multi: true
    },
    ...(environment.initializeSentry
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false
            })
          },
          {
            provide: Sentry.TraceService,
            deps: [Router]
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
          }
        ]
      : [])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
