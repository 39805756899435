<div content>
  <h3>User</h3>
  <div class="row">
    <mat-form-field appearance="fill" class="col-1" id="environment">
      <mat-label>Select an option</mat-label>
      <mat-select [(value)]="activeUser" data-test="environment">
        <mat-option value="PASS_THROUGH">Real database (AWS)</mat-option>
        <mat-option value="FT_USER_EXTERNALUSER_TOKEN">External User</mat-option>
        <mat-option value="FT_USER_EXTERNALSERVICE_TOKEN">External Service User</mat-option>
        <mat-option value="FT_USER_EXTERNALPARTNER_TOKEN">External Partner User</mat-option>
        <mat-option value="FT_USER_SICKSALES_TOKEN">Sick Sales User</mat-option>
        <mat-option value="FT_USER_GBCSALES_TOKEN">EHS Sales User</mat-option>
        <mat-option value="FT_USER_GBCSERVICE_TOKEN">EHS Service User</mat-option>
        <mat-option value="FT_USER_GBCEXPERT_TOKEN">EHS Expert User</mat-option>
        <mat-option value="FT_USER_ADMIN_TOKEN">Admin</mat-option>
        <mat-option value="FT_USER_SICK_TOKEN_LOCAL">User Token - but local</mat-option>
      </mat-select>
    </mat-form-field>

    <button class="col-2" mat-flat-button color="secondary" data-test="submit-environment" (click)="applyAndReload()">
      Apply & Reload
    </button>
  </div>

  <hr />

  <h3>Compute Interceptor</h3>

  <mat-checkbox [checked]="computeInterceptor" (change)="toggleComputeInterceptor()"> Compute interceptor </mat-checkbox>

  <hr />

  <h3>Actions</h3>

  <div class="row">
    <button mat-flat-button color="secondary" class="col-1" (click)="seedDb()">Seed DB</button>

    <button mat-flat-button color="secondary" class="col-2" (click)="clearDb()">Clear DB</button>

    <button mat-flat-button color="secondary" class="col-3" (click)="upgradeAll()">Upgrade all projects</button>

    <button mat-flat-button color="secondary" class="col-4" (click)="badRequest()">Bad Request</button>

    <button mat-flat-button color="secondary" class="col-1" (click)="copyDb()">Copy DB</button>
    <mat-form-field class="col-2" appearance="fill">
      <mat-label>From Env</mat-label>
      <mat-select [(value)]="sourceenv">
        <mat-option *ngFor="let env of envs" [value]="env">
          {{ env }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>To Env</mat-label>
      <mat-select [(value)]="targetenv">
        <mat-option *ngFor="let env of envsWoProd" [value]="env">
          {{ env }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Table to copy</mat-label>
      <mat-select [(value)]="table2Copy">
        <mat-option *ngFor="let table of tables" [value]="table">
          {{ table }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-flat-button color="secondary" class="col-1" (click)="clearTable()">Clear Table</button>
    <mat-form-field class="col-2" appearance="fill">
      <mat-label>Table</mat-label>
      <mat-select [(value)]="table2Delete">
        <mat-option *ngFor="let table of tables" [value]="table">
          {{ table }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <h3>Migrations</h3>
  <div>
    <button mat-flat-button color="secondary" class="col-1" (click)="migrateProjects()">Migrate Projects DB</button>
  </div>
</div>
