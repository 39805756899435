import {defaultEnvironment, Environment} from './default-environment';

export const environment: Environment = {
  ...defaultEnvironment,
  name: 'prod',
  oidc: {
    ...defaultEnvironment.oidc,
    issuer: 'https://myendress.b2clogin.com/655e8060-27db-4cdc-a06f-639c139d4259/B2C_1A_SignIn/v2.0/',
    clientId: '790c6697-743f-4371-a607-1662bdbf4630'
  },
  url: {
    aws: `https://guhkljvq9d.execute-api.eu-central-1.amazonaws.com/prod/app`,
    flowTwin: 'https://flowsic-applicationdesign.endress.com/'
  },
  production: true,
  initializeSentry: true
};
