import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';
import {Router, RouterOutlet} from '@angular/router';
import {TitleService} from '@core/services/title/title.service';
import {slideInAnimation} from './animations';
import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {VersionReduxAction} from '@state/actions/actions-version';
import {StateService} from '@state/state-service/state.service';
import {distinctUntilChanged, filter, map, take, timeout} from 'rxjs/operators';
import {AppModeService} from '@core/service-mode/app-mode.service';
import {USER_LOGIN_TIMEOUT} from '@shared/constants';

@Component({
  selector: 'da-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  constructor(
    private stateService: StateService,
    private titleService: TitleService,
    private translationService: TranslateService,
    private router: Router,
    private appModeService: AppModeService
  ) {}

  async ngOnInit() {
    this.stateService.loggedIn$.pipe(timeout(USER_LOGIN_TIMEOUT), take(1)).subscribe({
      next: () => {
        this.initTranslations();

        this.initApplication();

        this.fetchApplicationsVersions();

        this.appModeService.fetchServiceModeState();
      },
      error: err => {
        if (err.name === 'TimeoutError' && this.router.url === '/no-flow-twin-user') {
          // do nothing, it is expected that the request times out on the /no-flow-twin-user page
        } else {
          throw err;
        }
      }
    });
  }

  private initTranslations() {
    registerLocaleData(en, 'en');
    registerLocaleData(de, 'de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translationService.setDefaultLang('en');
    this.translationService.use('en');
    this.titleService.connect();

    this.stateService.state$
      .pipe(
        map(s => s.user.document.locale),
        filter(locale => locale === 'de' || locale === 'en'),
        distinctUntilChanged()
      )
      .subscribe(locale => {
        if (this.translationService.currentLang !== locale) {
          this.translationService.use(locale);
        }
      });
  }

  private initApplication() {
    this.stateService.state$
      .pipe(
        map(s => s),
        filter(s => s.appMode.mode !== 'OPERATIONAL'),
        take(1)
      )
      .subscribe(state => {
        const appMode = state.appMode.mode;
        const isAdmin = state.user.document.permissions.admin;

        if (!isAdmin) {
          if (this.router.url !== '/service' && appMode === 'SERVICE') {
            this.router.navigate(['/service']);
          }
        }
      });
  }

  private fetchApplicationsVersions() {
    this.stateService.state$.pipe(take(1)).subscribe(() => {
      const fetchVersions: VersionReduxAction = new VersionReduxAction('VERSIONS_GET_ALL_LOAD', {payload: null});

      this.stateService.dispatch(fetchVersions);
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
